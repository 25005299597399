import React from "react"
import ckuLogo from "../images/logo_combo.png"

const Header = ({ siteTitle }) => (
  <div className="header-container">
    <div className="logo">
      <a href="https://www.cleverkidsu.com/">
        <img src={ckuLogo} alt={siteTitle} width="100%" />
      </a>
    </div>
  </div>
)

export default Header
