import React from "react"
import { Col, Divider, Row, Typography } from "antd"
import AppDescription from "../appDescription"
import { FORM_STORAGE_KEY } from "./form"

import congratulationsImage from "../../images/pals-signup.png"

const { Paragraph, Title } = Typography

class SignedUpMessage extends React.Component {
  state = {}
  componentDidMount() {
    const form_data = JSON.parse(window.sessionStorage.getItem(FORM_STORAGE_KEY));
    if(form_data && form_data.waiting && form_data.values && form_data.values.email){
      this.setState({ email: form_data.values.email });
      window.sessionStorage.clear();
    }
    else {
      window.location.pathname = '/';
    }
  }

  render() {
    if(!this.state.email){ return null; }
    return (
      <Row type="flex" justify="center">
        <Col xs={20} sm={16} lg={16}>
          <div className="congrats-image">
            <img src={congratulationsImage} width="100%" alt="success" />
          </div>
          <div style={{ textAlign: "center" }}>
            <Paragraph className="centered-text">
              <Title level={3}>
                Your username: <br></br>
                <span className="user-email">{this.state.email}</span>
              </Title>
              <p className="hint-text">
                <i>Hint: Use the password you just set up.</i>
              </p>
            </Paragraph>
            <Divider />
          </div>
          <Row type="flex" justify="center">
            <Col span={24} textAlign="center">
              <AppDescription appType={"cku_red"} />
              <AppDescription appType={"cku_orange"} />
              <Paragraph className="centered-text">
                For cancellations or to add another child, contact{" "}
                <a href="mailto:support@cleverkidsu.com">
                  support@cleverkidsu.com{" "}
                </a>
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default SignedUpMessage
