import { Card, Col, Row, Typography } from "antd"

import React from "react"
import amazonBadge from "../images/amazon-badge.png"
import appstoreBadge from "../images/appstore-badge.png"
import googleBadge from "../images/google-badge.png"
import iCanReadUrl from "../images/app-icanread.png"
import preReaderUrl from "../images/app-prereader.png"
import windowsBadge from "../images/windows-badge.png"

const { Title, Text } = Typography
const AppDescription = props => {
  const preReader = {
    url: preReaderUrl,
    title: "Clever Kids University: Pre-Reader",
    description:
      "prepare for kindergarten by learning letters, numbers and more.",
    appleDownload:
      "https://itunes.apple.com/us/app/clever-kids-university-pre-k/id1336586533?ls=1&mt=8",
    googleDownload:
      "https://play.google.com/store/apps/details?id=air.com.myf2b.f2bcleverkids&hl=en",
    amazonDownload:
      "https://www.amazon.com/Footsteps2Brilliance-Clever-Kids-University-Pre-K/dp/B0798TLPCB/ref=sr_1_1?crid=1ETWBYDASE4OY&keywords=clever+kids+university+pre-k&qid=1560971176&s=mobile-apps&sprefix=clever+kids+university+%2Cmobile-apps%2C149&sr=1-1",
    windowsDownload:
      "https://www.microsoft.com/en-us/p/clever-kids-university-pre-k/9np9lrbnnv2f?activetab=pivot:overviewtab",
  }

  const iCanRead = {
    url: iCanReadUrl,
    title: "Clever Kids University: I Can Read",
    description:
      "become a reader with step by step phonics and reading lessons.",
    appleDownload:
      "https://apps.apple.com/us/app/clever-kids-u-i-can-read/id1465253099?ls=1",
    googleDownload:
      "https://play.google.com/store/apps/details?id=air.com.myf2b.f2bcleverkidsorange&hl=en_US",
    amazonDownload:
      "https://www.amazon.com/Clever-Kids-University-Can-Read/dp/B07T2CW5C9/ref=sr_1_1?keywords=clever+kids+university%3A+I+Can+read&qid=1560970929&s=mobile-apps&sr=1-1",
    windowsDownload:
      "https://www.microsoft.com/en-us/p/clever-kids-university-i-can-read/9nkg13www7v7?activetab=pivot:overviewtab",
  }

  const appOptions = {
    cku_red: preReader,
    cku_orange: iCanRead,
  }

  if (!appOptions[props.appType]) {
    return null
  }
  return (
    <div className="description-block">
      <Card className="app-description-card">
        <Row type="flex" justify="center" align="middle">
          <Col className="centered-text">
            <Col xs={24} sm={4} md={4} lg={6} style={{ marginRight: 10 }}>
              <img
                className="app-icon"
                src={appOptions[props.appType].url}
                alt={appOptions[props.appType].url}
              />
            </Col>
            <Col xs={24} sm={18} md={18} lg={16}>
              <div style={{ marginTop: 10, marginBottom: 15 }}>
                <Text>
                  <span style={{ fontWeight: "bold" }}>
                    {appOptions[props.appType].title}
                  </span>{" "}
                  {appOptions[props.appType].description}
                </Text>
              </div>
              <Row type="flex" justify="space-around" gutter={[8, 8]}>
                <Col xs={10} sm={6} lg={6}>
                  <a
                    href={appOptions[props.appType].appleDownload}
                    target="_blank"
                  >
                    <img
                      src={appstoreBadge}
                      alt="app-store-badge"
                      width="100%"
                    />
                  </a>
                </Col>
                <Col xs={10} sm={6} lg={6}>
                  <a
                    href={appOptions[props.appType].amazonDownload}
                    target="_blank"
                  >
                    <img
                      src={amazonBadge}
                      alt="amazon-store-badge"
                      width="100%"
                    />
                  </a>
                </Col>
                <Col xs={10} sm={6} lg={6}>
                  <a
                    href={appOptions[props.appType].googleDownload}
                    target="_blank"
                  >
                    <img
                      src={googleBadge}
                      alt="google-play-badge"
                      width="100%"
                    />
                  </a>
                </Col>
                <Col xs={10} sm={6} lg={6}>
                  <a
                    href={appOptions[props.appType].windowsDownload}
                    target="_blank"
                  >
                    <img
                      src={windowsBadge}
                      alt="windows-store-badge"
                      width="100%"
                    />
                  </a>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default AppDescription
