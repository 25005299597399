/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Layout as LayoutA } from "antd"
import "../theme/theme.css"

import Header from "./header"

const Layout = ({ children }) => {
  return (
    <main className="main-container">
      <LayoutA className="layout-container">
        <LayoutA.Header
          theme="light"
          className="header-bar"
        >
          <Header />
        </LayoutA.Header>
        <LayoutA.Content style={{flexGrow: 0}}>
          {children}
        </LayoutA.Content>
        <LayoutA.Footer
          flex="auto"
          theme="light"
          className="footer-bar"
          style={{ textAlign: "center" }}
        >
          Copyright &copy; 2020 Footsteps2Brilliance, Inc.
        </LayoutA.Footer>
      </LayoutA>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
