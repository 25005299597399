import React from "react"
import SiteLayout from "../components/layout"
import SEO from "../components/seo"
import SignedUpMessage from "../components/registration/signed_up_message"
import { Layout } from "antd"

const IndexPage = () => (
	<SiteLayout>
		<SEO title="Success" />
		<Layout style={{height: "100%", backgroundColor: "#fff"}}>
			<Layout.Content>
				<SignedUpMessage success={true} />
			</Layout.Content>
		</Layout>
	</SiteLayout>
)

export default IndexPage
